import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static classes = ["visible", "hidden"]

	connect() {
		if (window.location.pathname == "/") {
			sessionStorage.removeItem("popupDismissed")
		}


		if (sessionStorage.getItem("popupDismissed") != "true") {
			this.element.classList.remove(this.hiddenClass)
			this.element.classList.add(this.visibleClass)
		}
	}

	close() {
		this.element.remove()
		sessionStorage.setItem("popupDismissed", "true")
	}
}
